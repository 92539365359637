import { Dispatch, SetStateAction, useState } from "react"

import {
  TLogFilterContext,
  usePostLogFilterApplied,
  usePostLogFilterCleared,
  usePostLogFilterHomeSearched,
  usePostLogFilterInitiated,
} from "src/data/analytics/queries/logAnalyticsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"

type HomeFilterDropdownProps = {
  homeIds: string[]
  setHomeIds: Dispatch<SetStateAction<string[]>>
  metaData?: { context: TLogFilterContext }
}

export function HomeFilterDropdown({
  homeIds,
  setHomeIds,
  metaData,
}: HomeFilterDropdownProps) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const [homeSearch, setHomeSearch] = useState("")
  const fetchHomes = useFetchHomes({
    orgId,
    filters: { name: homeSearch },
  })
  const homes = fetchHomes.data?.homes || []
  const homeOptions: TSelectOption<string>[] = homes.map((h) => ({
    value: h.home_id,
    label: h.name || h.home_id,
  }))

  const postLogFilterApplied = usePostLogFilterApplied()
  const postLogFilterHomeSearched = usePostLogFilterHomeSearched()
  const postLogFilterCleared = usePostLogFilterCleared()
  const postLogFilterInitiated = usePostLogFilterInitiated()

  function handleHomeSearch(searchValue: string) {
    setHomeSearch(searchValue)

    if (!!searchValue && metaData?.context) {
      postLogFilterHomeSearched.mutate({
        search_term: searchValue,
        context: metaData.context,
      })
    }
  }

  function handleHomeChange({
    checked,
    option,
  }: {
    checked: boolean
    option: TSelectOption<string>
  }) {
    if (checked) {
      // At this point he BE will accept up to about(750 chars) 30 ids
      // in the home_ids parameter so we are limiting the number of
      // homeOptions sent to to the BE to 30
      setHomeIds((prev) =>
        prev.length < 30 ? Array.from(new Set([...prev, option.value])) : prev
      )

      if (metaData?.context) {
        postLogFilterApplied.mutate({
          filter_type: "home",
          num_of_homes: homeIds.length + 1,
          context: metaData.context,
        })
      }
    } else {
      setHomeIds((prev) => prev.filter((o) => o !== option.value))
    }
  }

  function handleClearSelection() {
    setHomeIds([])

    if (metaData?.context) {
      postLogFilterCleared.mutate({
        filter_type: "home",
        context: metaData.context,
      })
    }
  }

  function handleClick() {
    if (metaData?.context) {
      postLogFilterInitiated.mutate({
        filter_type: "home",
        context: metaData.context,
      })
    }
  }

  return (
    <DropdownMultiSelect
      label={t(langKeys.home, { count: 1 })}
      placeholder={t(langKeys.search)}
      options={homeOptions}
      selectedValues={homeIds}
      onChange={handleHomeChange}
      onSearch={handleHomeSearch}
      onClearSelection={handleClearSelection}
      loading={fetchHomes.isLoading}
      onClick={handleClick}
    />
  )
}
