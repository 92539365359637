import { useMemo, useState } from "react"
import styled from "styled-components"

import { endOfDay, startOfDay } from "date-fns"

import { NoEvents } from "src/components/EmptyState/NoEvents"
import { HomeFilterDropdown } from "src/components/HomeLog/HomeFilterDropdown"
import { HomeLogDateRangePicker } from "src/components/HomeLog/HomeLogDateRangePicker"
import { HomeLogFilteredEmptyState } from "src/components/HomeLog/HomeLogEmptyState"
import { HomeLogEventFilterDropdown } from "src/components/HomeLog/HomeLogEventFilterDropdown"
import { HomeLogTableGlobal } from "src/components/HomeLog/HomeLogTableGlobal"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { mapFilterGroupsToHomeEventTypes } from "src/data/events/logic/eventsLogic"
import {
  useFetchHomeEventsGlobal,
  useFetchHomeEventsGlobalCount,
} from "src/data/events/queries/homeEventQueries"
import { THomeEventFilterGroup } from "src/data/events/types/homeEventTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { DebugBox } from "src/ui/Debug/DebugBox"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import { MainView } from "src/ui/Layout/MainView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function HomeLogGlobal() {
  const metaDataTrackingContext = "home_log_global"

  const { limit, offset, setOffset } = useUrlPager({ initialLimit: 20 })
  const [eventFilter, setEventFilter] = useState<THomeEventFilterGroup[]>([])

  const [dateRange, setDateRange] = useState<{
    startDate: Date | null
    endDate: Date | null
  }>({
    startDate: null,
    endDate: null,
  })

  const [homeIds, setHomeIds] = useState<TSelectOption<string>["value"][]>([])

  const eventTypeFilter = useMemo(() => {
    return mapFilterGroupsToHomeEventTypes(eventFilter)
  }, [eventFilter])

  const fetchHomeEventsCount = useFetchHomeEventsGlobalCount()

  const fetchHomeEvents = useFetchHomeEventsGlobal({
    filters: {
      limit,
      offset,
      event_type: eventTypeFilter.join(","),
      start_at: !!dateRange.startDate
        ? startOfDay(dateRange.startDate).toISOString()
        : undefined,
      end_at: !!dateRange.endDate
        ? endOfDay(dateRange.endDate).toISOString()
        : undefined,
      home_ids: homeIds.length ? homeIds.join(",") : undefined,
    },
  })
  const homeEvents = fetchHomeEvents.data?.home_events || []
  const totalCount = fetchHomeEvents.data?.paging.total_count || 0
  const hasNoEvents = !homeEvents.length && !fetchHomeEvents.isInitialLoading
  const isFiltered =
    homeIds.length > 0 ||
    eventTypeFilter.length > 0 ||
    !!dateRange.startDate ||
    !!dateRange.endDate
  const hasNoEventsOnFilter = hasNoEvents && isFiltered
  // NB: If the user sets a date range it cannot be cleared by the user so
  // isFiltered will always be true; this will need to be fixed witin the
  // DateRangePicker component though it's probably not worth the effort.

  if (fetchHomeEventsCount.isLoading) {
    return (
      <HomeLogGlobalLayout>
        <MSkeleton height={30} />
        <MSkeleton height={30} />
        <MSkeleton height={30} />
      </HomeLogGlobalLayout>
    )
  }

  if (fetchHomeEventsCount.data === 0) {
    return (
      <HomeLogGlobalLayout>
        <NoEvents />
      </HomeLogGlobalLayout>
    )
  }

  return (
    <HomeLogGlobalLayout>
      <FilterBox>
        <HomeFilterDropdown
          homeIds={homeIds}
          setHomeIds={(ids) => {
            setOffset(0)
            setHomeIds(ids)
          }}
          metaData={{ context: metaDataTrackingContext }}
        />

        <HomeLogEventFilterDropdown
          loading={fetchHomeEvents.isLoading}
          eventFilter={eventFilter}
          setEventFilter={(filter) => {
            setEventFilter(filter)
            setOffset(0)
          }}
          metaData={{ context: metaDataTrackingContext }}
        />

        <HomeLogDateRangePicker
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          dateRangeProps={{ minBookingDays: 2 }}
          setDateRange={setDateRange}
          setOffset={setOffset}
          metaData={{ context: metaDataTrackingContext }}
        />
      </FilterBox>

      <MSpacer />

      <HomeLogTableGlobal
        homeEvents={homeEvents}
        loading={fetchHomeEvents.isLoading}
      />
      <DebugBox data={homeEvents} />

      {hasNoEventsOnFilter && <HomeLogFilteredEmptyState />}

      <XLSpacer />

      <Pager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={totalCount}
      />
    </HomeLogGlobalLayout>
  )
}

function HomeLogGlobalLayout({ children }: { children: React.ReactNode }) {
  const { t, langKeys } = useTranslate()

  return <MainView title={t(langKeys.log)}>{children}</MainView>
}

const FilterBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.M};
  margin-bottom: ${spacing.M};
`

const MSpacer = styled.div`
  margin-top: ${spacing.M};
`

const XLSpacer = styled.div`
  margin-top: ${spacing.XL};
`
